
<template>
    <div>
        <request-data-table :isloading="isOutboxDatasLoading" :inbox="false" :dash="'company'" :headers="headers"
            :content="outboxDatas"></request-data-table>
    </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import RequestDataTable from '@/views/dashboard/requests/components/RequestDataTable.vue'
import { Drequest } from '@/plugins/Drequest'

export default {
    props: {
        owner: {
            type: String,
            default: 'dashu'
        }
    },
    data() {
        return {
            isOutboxDatasLoading: true,
            outboxDatas: [],
        }
    },
    components: {
        RequestDataTable
    },

    beforeMount() {
        this.isOutboxDatasLoading = true
        if (this.owner === 'dashc') {
            Drequest.api(`lazyloading.rfpenterprise?dfilters=on&rfp.enterprise_id:eq=${this.$store.getters.getCompany.id}`)
                .get((response) => {
                    if (response.success) {
                        response.listEntity.forEach((value, index) => {
                            this.outboxDatas.push({
                                id: value.id,
                                status: value.status,
                                recipient: ((value.enterprise.id) === null && (value.user.id !== null)) ? value.user.firstname : (((value.enterprise.id) !== null && (value.user.id === null)) ? value.enterprise.name : 'unknown'),
                                owner: value.rfp.enterprise.name,
                                email: value.enterprise.email,
                                user: ((value.enterprise.id) === null && (value.user.id !== null)) ? "1" : (((value.enterprise.id) !== null && (value.user.id === null)) ? "0" : '-1'),
                                request: value.rfp,
                                sent_at: value.sent_at
                            })
                            console.log({
                                id: value.id,
                                status: value.status,
                                recipient: value.enterprise.name,
                                owner: value.rfp.enterprise.name,
                                email: value.enterprise.email,
                                user: "0",
                                request: value.rfp,
                                sent_at: value.sent_at
                            })
                        })
                        this.isOutboxDatasLoading = false
                    }
                    else {
                        this.$fire({
                            type: "error",
                            text: "Reload page..!",
                            timer: 3000
                        })
                        this.isOutboxDatasLoading = false
                    }
                })
                .catch((err) => {
                    console.log("err")
                    this.isOutboxDatasLoading = false
                })
        }
        // if (this.owner === 'dashu') {
        //     Drequest.api(`lazyloading.rfpuser?dfilters=on&rfp.user_id:eq=${this.$store.getters.getUser.id}`)
        //         .get((response) => {
        //             if (response.success) {
        //                 response.listEntity.forEach((value, index) => {
        //                     this.outboxDatas.push({
        //                         id: value.id,
        //                         status: value.status,
        //                         recipient: value.user.firstname,
        //                         owner: value.rfp.enterprise.name,
        //                         email: value.user.email,
        //                         user: "1",
        //                         request: value.rfp,
        //                         sent_at: value.sent_at
        //                     })
        //                 })
        //                 this.isOutboxDatasLoading = false
        //             }
        //             else {
        //                 this.$fire({
        //                     type: "error",
        //                     text: "Reload page..!",
        //                     timer: 3000
        //                 })
        //                 this.isOutboxDatasLoading = false
        //             }
        //         })
        //         .catch((err) => {
        //             console.log("err")
        //             this.isOutboxDatasLoading = false
        //         })
        // }

    },
    setup() {
        const headers = [
            {
                text: 'Type',
                align: 'start',
                sortable: true,
                value: 'type'
            },
            {
                text: 'To',
                align: 'start',
                sortable: true,
                value: 'ruser'
            },
            {
                text: 'Object',
                align: 'start',
                sortable: true,
                value: 'object'
            },
            {
                text: 'Status',
                align: 'start',
                sortable: true,
                value: 'status'
            },
            { text: 'Actions', value: 'actions', sortable: false },
        ]

        return {
            headers,
        }
    },
}
</script>

<style lang="scss" scoped>
</style>
